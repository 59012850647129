<template>
  <VariableKpiDivisiForm mode="Tambah" module="Variable KPI"> </VariableKpiDivisiForm>
</template>

<script>
import VariableKpiDivisiForm from './form';

const VariableKpiDivisiAdd = {
  name: 'VariableKpiDivisiAdd',
  components: { VariableKpiDivisiForm },
};

export default VariableKpiDivisiAdd;
</script>
