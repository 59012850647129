<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="parent_id" label="Induk Kegiatan">
            <a-select
              placeholder="Pilih Induk Kegiatan"
              v-model:value="formState.parent_id"
              show-search
              :filter-option="filterSelect"
              :disabled="mode == 'Ubah' ? true : false"
            >
              <a-select-option v-for="parent in options.parents" :key="parent.value">
                {{ parent.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="kegiatan" label="Kegiatan">
            <a-input v-model:value="formState.kegiatan" placeholder="Masukkan kegiatan" />
          </a-form-item>

          <a-form-item name="penerima_manfaat" label="Penerima Manfaat">
            <a-input v-model:value="formState.penerima_manfaat" placeholder="Masukkan penerima manfaat" />
          </a-form-item>

          <a-form-item name="waktu" label="Waktu">
            <a-input v-model:value="formState.waktu" placeholder="Masukkan waktu" />
          </a-form-item>

          <a-form-item name="realisasi_dana" label="Realisasi Dana">
            <a-input v-model:value="formState.realisasi_dana" placeholder="Masukkan realisasi dana" />
          </a-form-item>

          <a-form-item name="keterangan" label="Keterangan">
            <a-input v-model:value="formState.keterangan" placeholder="Masukkan keterangan" />
          </a-form-item>

          <a-row style="margin-bottonm:12px;">
            <a-col :lg="{ span: 20, offset: 4 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link to="/laporan-manajemen/kegiatan-crs">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted, toRaw } from 'vue';
import { useRoute } from 'vue-router';

const VariableKpiDivisiForm = {
  name: 'VariableKpiDivisiForm',
  components: { HorizontalFormStyleWrap, Main },
  data() {
    return {
      title: this.mode + ' ' + 'Kegiatan CSR',
    };
  },
  props: ['mode', 'module'],
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);

    const formState = reactive({
      id: undefined,
      parent_id: null,
      kegiatan: '',
      penerima_manfaat: '',
      waktu: '',
      realisasi_dana: '',
      keterangan: '',
    });

    const labelCol = {
      lg: 4,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 20,
      md: 15,
      xs: 24,
    };

    const handleSubmit = () => {
      let dataParams = toRaw(formState);
      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitData', {
          url: 'laporan-kegiatan-crs',
          data: dataParams,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosDataUpdate', {
          id: params.itemId,
          url: 'laporan-kegiatan-crs',
          data: dataParams,
        });
      }
    };

    let inputStringRule = [
      {
        required: true,
        message: 'Harap Diisi',
        trigger: 'blur',
      },
    ];
    const rules = {
      month_year: inputStringRule,
      target: [
        {
          type: 'number',
          required: true,
          min: 1,
          message: 'Harap Diisi, Minimum 1 Kegiatan',
          trigger: 'blur',
        },
      ],
      tanggal_pelaksanaan: [
        {
          type: 'object',
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const options = reactive({
      parents: [],
    });

    const filterSelect = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    onMounted(() => {
      dispatch('axiosCrudGetData', 'properties-kegiatan-crs').then(() => {
        console.log(crud.value);
        options.parents = crud.value.data.parents;
      });

      if (props.mode == 'Ubah') {
        const data = {
          url: 'laporan-kegiatan-crs',
          id: params.itemId,
        };

        dispatch('axiosSingleDataGet', data).then(() => {
          formState.id = crud.value.id;
          formState.parent_id = crud.value.parent_id;
          formState.kegiatan = crud.value.kegiatan;
          formState.penerima_manfaat = crud.value.penerima_manfaat;
          formState.waktu = crud.value.waktu;
          formState.realisasi_dana = crud.value.realisasi_dana;
          formState.keterangan = crud.value.keterangan;
        });
      }
    });

    return {
      formState,
      labelCol,
      wrapperCol,
      handleSubmit,
      isLoading,
      rules,
      options,
      filterSelect,
    };
  },
};

export default VariableKpiDivisiForm;
</script>
